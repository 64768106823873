<template>
  <div>
    <div flat style="height: 100%; position: relative" class="signature">
      <v-card-text class="bold-f-s text-center p-t-36">PLEASE SIGN YOUR LOAN AGREEMENT.</v-card-text>
      <v-card-text class="p-0 width-full" style="height: calc(100% - 74px)">
        <iframe :src="eswUrl" class="position-rel width-full height-full no-border"></iframe>
      </v-card-text>
      <v-dialog
        persistent
        max-width="480" v-model="disclosure">
        <v-card flat style="padding: 20px" class="b-r-16">
          <v-btn @click="disclosure=false" icon style="position: absolute; top: 0px; right: 0px">
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-title>
            <v-spacer></v-spacer>
            <img height="120px" src="../../assets/icon/warning.png"/>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="f-s-24 bold-f text-center">
            Attention Please
          </v-card-text>
          <v-card-text>
            This is a <b>very expensive</b> form of borrowing. <span class="bold-f">{{portfolio.displayName}}</span> are
            designed to assist you in
            meeting your short-term borrowing needs and are not intended to be a long-term financial solution.
          </v-card-text>
          <v-card-text>
            <span class="bold-f">{{portfolio.displayName}}</span> never charges any prepayment penalties on partial or
            complete repayments. Prompt repayment of
            your loan will reduce the costs associated with borrowing and is strongly recommended by <span class="bold-f">{{portfolio.displayName}}</span>.
            <v-icon color="primary" @click="showModal = true">info</v-icon>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        persistent
        max-width="480" v-model="showModal">
        <v-card flat style="padding: 20px" class="b-r-16">
          <v-btn @click="showModal=false" icon style="position: absolute; top: 0px; right: 0px">
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-title>You have 3 options to pay-off your loan:</v-card-title>
          <v-card-text>
            <span class="bold-f">Option #1</span>: ’Default Payment’ Option, following the payment schedule on the loan
            agreement.
          </v-card-text>
          <v-card-text>
            <span class="bold-f">Option #2</span>: ‘Pay Down’ Option, adding an amount of $20.00 or more to your scheduled
            payment so that
            you can pay off the loan faster and reduce the total finance fees. An advanced notice of 3 business days is
            required.
          </v-card-text>
          <v-card-text>
            <span class="bold-f">Option #3</span>: ‘Pay in Full’ Payment Option: You can pay your loan in full without
            prepayment penalty.
            An advanced notice of 3 business days is required.
          </v-card-text>
          <v-card-text>
            <strong>{{toUppercase(portfolio.displayName)}} STRONGLY RECOMMENDS YOU TO CONSIDER OPTION #2 OR #3.</strong>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { Tool, DataType } from '../../js/core'
import { OriginationApi } from '../../api/application'

const tool = new Tool()
export default {
  components: {},
  data () {
    return {
      page: DataType.PAGES.SIGNATURE,
      portfolio: {},
      disclosure: true,
      showModal: false,
      applicationId: '',
      eswUrl: ''
    }
  },
  props: {
    sloth: Object
  },
  methods: {
    toUppercase (str) {
      if (str) {
        return str.toUpperCase()
      }
    },
    onMessageSloth () {
      const _this = this
      this.sloth.client.on('message', function (message) {
        message = message.data
        switch (parseInt(message.ticket)) {
          case DataType.LoanActionEnum.CHANGE_SIGNATURE_STATUS.value: {
            _this.next()
            break
          }
          default:
            break
        }
      })
    },
    next () {
      const stepMessage = {
        currect: DataType.PAGES.SIGNATURE,
        next: DataType.PAGES.SUCCESS
      }
      this.$eventBus.$emit('sendStepToSloth', stepMessage)
      OriginationApi.enteredAndLeft(
        localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        DataType.PAGES.SIGNATURE.step,
        DataType.PAGES.SUCCESS.step,
        localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID))
      this.$router.push(DataType.PAGES[this.page.next].addressPC)
    }
  },
  destroyed () {
    this.$eventBus.$off('initSloth')
  },
  async mounted () {
    const _this = this
    _this.$store.commit('setCurrentPage', _this.page)
    _this.$store.commit('setOverlay', true)
    const wsid = localStorage.getItem(DataType.COOKIE_KEY.WSID)
    const params = {
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      wsid: wsid
    }
    if (this.sloth.client !== null) {
      _this.onMessageSloth()
    } else {
      this.$eventBus.$emit('verification')
      this.$eventBus.$on('openSloth', () => {
        _this.onMessageSloth()
      })
    }
    if (tool.isNotEmpty(this.$store.getters.getRedirectUrl)) {
      _this.eswUrl = this.$store.getters.getRedirectUrl
      _this.$eventBus.$emit('sendSignatureStatus')
    } else {
      await OriginationApi.blackLinkSignature(params, function (result) {
        _this.eswUrl = result.documentUrl + '?embed=1'
        _this.$eventBus.$emit('sendSignatureStatus')
      })
    }

    _this.$store.commit('setOverlay', false)
    this.portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
  }
}
</script>
<style lang="css">
  .signature.container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 112px) !important;
    width: 80%;
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    padding: 20px;
  }
</style>
